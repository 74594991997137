import React from 'react';
import Layout from '../components/layout';

const CookiePolicyPage = () => {
  return (
    <Layout styles="cookie-policy" title="Cookie Policy">
      Cookie Policy Here
    </Layout>
  );
};

export default CookiePolicyPage;
